import React, {useState, useEffect, Fragment} from 'react';
import axios from 'axios';
import moment from 'moment';
import {Calendar, momentLocalizer} from 'react-big-calendar';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css'
import 'react-big-calendar/lib/css/react-big-calendar.css';
import {API_BASE_URL} from '../config';
import Modal from 'react-modal';
import {DndProvider, useDrag, useDrop} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';
import '../assets/css/Calendar.css';
import TaskCard from '../components/Trello/components/TaskCard';

import 'react-big-calendar/lib/addons/dragAndDrop/styles.css'

const DnDCalendar = withDragAndDrop(Calendar)
const localizer = momentLocalizer(moment);

moment.updateLocale('fr', {
    weekdays: ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"],
    weekdaysShort: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
    months: ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"],
    monthsShort: ["Jan", "Fév", "Mar", "Avr", "Mai", "Juin", "Juil", "Août", "Sep", "Oct", "Nov", "Déc"],
    longDateFormat: {
        LT: "HH:mm",
        LTS: "HH:mm:ss",
        L: "DD/MM/YYYY",
        LL: "D MMMM YYYY",
        LLL: "D MMMM YYYY HH:mm",
        LLLL: "dddd D MMMM YYYY HH:mm"
    },
    week: {
        dow: 1 // Set Monday as the first day of the week
    }
});

const Calendrier = () => {
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [events, setEvents] = useState([]);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [currentTask, setCurrentTask] = useState(null);

    useEffect(() => {
        document.title = "X | Calendrier"

        axios.post(`${API_BASE_URL}/get_users`)
            .then(res => {
                setUsers(res.data);
                const defaultUserId = localStorage.user_id || res.data[0].id;
                setSelectedUser(defaultUserId); // Select the first user by default
            })
            .catch(err => {
                console.error(err);
            });
    }, []);

    useEffect(() => {
        if (selectedUser) {
            axios.post(`${API_BASE_URL}/get_user_tasks`, {
                id: selectedUser,
                number: null, // Or remove `number` entirely if not needed
            })
                .then(res => {
                    console.log(res.data)
                    const tasks = res.data.data.map(task => ({
                        id: task.id,
                        title: task.title,
                        start: new Date(task.finish_date),  // Ensure your start_time field is a valid date string
                        end: new Date(task.finish_date),      // Ensure your end_time field is a valid date string
                        userId: task.user_id,
                    }));
                    setEvents(tasks); // Set the events for the calendar
                    console.log(events)
                    console.log(tasks)
                })
                .catch(err => {
                    console.error(err);
                });
        }
    }, [selectedUser]);

    const handleUserChange = (e) => {
        const userId = e.target.value;
        setSelectedUser(userId);
    };

    const handleTaskClick = (task) => {
        setCurrentTask(task);
        setModalIsOpen(true);
    };

    const handleDateChange = (field, value) => {
        setCurrentTask({
            ...currentTask,
            [field]: value
        });
    };

    const handleModalClose = () => {
        if (currentTask.end < currentTask.start) {
            [currentTask.start, currentTask.end] = [currentTask.end, currentTask.start];
        }

        axios.post(`${API_BASE_URL}/update_user_calendar`, {task: currentTask, id: selectedUser})
            .then((res) => {
                setEvents(events.map(event => (event.id === currentTask.id ? currentTask : event)));
                setModalIsOpen(false);
            })
            .catch(err => {
                console.error(err);
            });
    };


    // This function handles the resizing of the task
    const handleEventResize = ({event, start, end}) => {
        const updatedTask = {...event, start, end};
        console.log(updatedTask)

        // Update the task on the server
        axios.post(`${API_BASE_URL}/update_user_calendar`, {task: updatedTask})
            .then(() => {
                // Update the task in the local state
                setEvents(events.map(evt => evt.id === event.id ? updatedTask : evt));
            })
            .catch(err => {
                console.error('Error updating task:', err);
            });
    };


    return (
        <Fragment>
            <div>
                <div className="account-container" >
                    <DndProvider backend={HTML5Backend}>
                        <h2>Calendrier Utilisateur</h2>

                        <div className={'display-flex flex-1 gap-10'}>
                            <div className={"display-grid"}>
                                <div className="user-list">
                                    <select onChange={handleUserChange} value={selectedUser}>
                                        {users.map(user => (
                                            <option key={user.id} value={user.id}>{user.username}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="task-list">
                                    {events.map(event => (
                                        <TaskCard key={event.id} task={event} onClick={handleTaskClick}/>
                                    ))}
                                </div>
                            </div>

                            <div className="calendar flex-4">
                                <DnDCalendar
                                    localizer={localizer}
                                    events={events}
                                    startAccessor="start"
                                    endAccessor="end"
                                    style={{height: 500}}
                                    culture="fr"
                                    draggableAccessor={() => true}
                                    onEventResize={handleEventResize}  // Pass the handler here
                                />

                            </div>
                        </div>

                        <Modal
                            isOpen={modalIsOpen}
                            onRequestClose={() => setModalIsOpen(false)}
                            contentLabel="Edit Task"
                        >
                            <h2>Edit Task</h2>
                            {currentTask && (
                                <div>
                                    <label>
                                        Start Date:
                                        <input
                                            type="datetime-local"
                                            value={moment(currentTask.start).format('YYYY-MM-DDTHH:mm')}
                                            onChange={(e) => handleDateChange('start', new Date(e.target.value))}
                                        />
                                    </label>
                                    <label>
                                        End Date:
                                        <input
                                            type="datetime-local"
                                            value={moment(currentTask.end).format('YYYY-MM-DDTHH:mm')}
                                            onChange={(e) => handleDateChange('end', new Date(e.target.value))}
                                        />
                                    </label>
                                    <button onClick={handleModalClose}>Save</button>
                                </div>
                            )}
                        </Modal>
                    </DndProvider>
                </div>
            </div>
        </Fragment>
)
    ;
};

export default Calendrier;
