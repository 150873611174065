import React, {useEffect, useState} from 'react';
import axios from "axios";
import {API_BASE_URL} from "../config";
import ReportingChart from "../components/ReportingChart";
import DatePicker, {registerLocale} from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../assets/css/Reporting.css';
import '../assets/css/ReportingGrid.css';
import {fr} from 'date-fns/locale';
import ReportingCard from "../components/ReportingCard";
import {getDateRange} from '../utils/dateUtils';
import Table from "../components/Table";
import Tasks from "../components/Tasks";

registerLocale('fr', fr);

const Reporting = () => {
    const [dailyAvg, setDailyAvg] = useState([]);
    const [totalAmount, setTotalAmount] = useState([]);
    const [totalTasks, setTotalTasks] = useState([]);
    const [selectedFilter, setSelectedFilter] = useState('lastWeek'); // Default filter
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [proratedData, setProratedData] = useState();
    const [isCustom, setIsCustom] = useState(false);

    useEffect(() => {
        document.title = 'X | Vue de la production'
    }, []);
    useEffect(() => {
        if (selectedFilter !== 'custom') {
            fetchData(selectedFilter); // Fetch data based on selected filter
        }
    }, [selectedFilter]);

    useEffect(() => {
        if (isCustom) {
            fetchData('custom', startDate, endDate); // Fetch data for custom duration
        }
    }, [startDate, endDate]);

    const fetchData = (filter, customStartDate, customEndDate) => {
        const dateRange = getDateRange(filter, customStartDate, customEndDate);
        const formattedStartDate = dateRange[0];
        const formattedEndDate = dateRange[dateRange.length - 1];
        console.log("Calculated Date Range:", dateRange);

        // Helper function to calculate the number of days between two dates
        const calculateDaysBetween = (startDate, endDate) => {
            const start = new Date(startDate);
            const end = new Date(endDate);
            const differenceInTime = end.getTime() - start.getTime();
            return differenceInTime / (1000 * 3600 * 24) + 1; // Include both start and end days
        };

        // API call: Get prorated task value per position
        axios.post(`${API_BASE_URL}/get_prorata_for_position`, {
            startDate: formattedStartDate,
            endDate: formattedEndDate
        })
            .then(response => {
                const proratedData = response.data.datas;
                setProratedData(proratedData);

                setTotalTasks(proratedData)
                console.log(proratedData)
                // Calculate the sum of the prorated amounts
                const totalAmount = proratedData.reduce((sum, item) => sum + (parseFloat(item.task_price_prorata) || 0), 0);
                setTotalAmount(totalAmount);

                // Calculate the number of days between the start and end dates
                const numOfDays = calculateDaysBetween(formattedStartDate, formattedEndDate);

                // Calculate the daily average
                const dailyAvg = totalAmount / numOfDays;
                setDailyAvg(dailyAvg);

                console.log("Daily Average:", dailyAvg);
            })
            .catch(error => {
                console.error('Error retrieving prorated task data:', error);
            });
    };

    const handleFilterChange = (filter) => {
        setSelectedFilter(filter);
        setIsCustom(filter === 'custom');
    };

    const handleDateChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
        if (start && end) {
            setSelectedFilter('custom');
            setIsCustom(true);
        }
    };

    return (
        <div>
            <h2>Vue de la production</h2>
            <div className={'display-flex'} style={{justifyContent: "flex-end"}}>
            </div>
            <div className="parent">
                <div className="div1">
                    <ReportingCard header={"revenus"}
                                   data={`${totalAmount.toLocaleString('en-CH', {minimumFractionDigits: 2})} CHF`}
                                   icon={'fa-sack-dollar'}/>
                </div>
                <div className="div2">
                    <ReportingCard header={"tâches terminées"} data={totalTasks.length} icon={'fa-flag-checkered'}/>
                </div>
                <div className="div3">
                    <ReportingCard header={"moyenne quotidienne"}
                                   data={`${dailyAvg.toLocaleString('en-CH', {minimumFractionDigits: 2})}`}
                                   icon={'fa-calendar-check'}/>
                </div>
                <div className="div4">
                    <ReportingChart data={proratedData} selectedFilter={selectedFilter} customStartDate={startDate}
                                    customEndDate={endDate}/>
                </div>
                <div className={'div5'} style={{flexDirection: "column"}}>
                    <div className={"display-grid"}>

                        <div className={"display-flex mb-10"}>
                            <button
                                className={`margin-0 flex-1 filter-btn ${selectedFilter === 'thisWeek' ? 'active-reporting' : ''}`}
                                onClick={() => handleFilterChange('thisWeek')}
                            >
                                Cette semaine
                            </button>
                            <button style={{width: "33%"}}
                                    className={`margin-0 flex-1 filter-btn ${selectedFilter === 'lastWeek' ? 'active-reporting' : ''}`}
                                    onClick={() => handleFilterChange('lastWeek')}
                            >
                                7 derniers jours
                            </button>
                            <button style={{width: "33%"}}
                                    className={`margin-0 flex-1 filter-btn ${selectedFilter === 'thisMonth' ? 'active-reporting' : ''}`}
                                    onClick={() => handleFilterChange('thisMonth')}
                            >
                                Ce mois
                            </button>

                        </div>
                        <div className={"display-flex"}>
                            <button style={{width: "33%"}}
                                    className={`margin-0 flex-1 filter-btn ${selectedFilter === 'last30Days' ? 'active-reporting' : ''}`}
                                    onClick={() => handleFilterChange('last30Days')}
                            >
                                30 derniers jours
                            </button>
                            <button style={{width: "33%"}}
                                    className={`margin-0 flex-1 filter-btn ${selectedFilter === 'thisYear' ? 'active-reporting' : ''}`}
                                    onClick={() => handleFilterChange('thisYear')}
                            >
                                Cette année
                            </button>

                            <button style={{width: "33%"}}
                                    className={`margin-0 flex-1 filter-btn ${selectedFilter === 'custom' ? 'active-reporting' : ''}`}
                                    onClick={() => handleFilterChange('custom')}
                            >
                                Durée personnalisée
                            </button>
                        </div>
                        {isCustom && (
                            <div className="date-picker-container">
                                <DatePicker
                                    locale="fr"
                                    selected={startDate}
                                    onChange={handleDateChange}
                                    startDate={startDate}
                                    endDate={endDate}
                                    selectsRange
                                    inline
                                    calendarStartDay={1} // Monday as the first day of the week
                                />
                            </div>
                        )}
                    </div>

                </div>
                <div className="div6">Cette vue répertorie toutes les tâches terminées pendant la période
                    sélectionnée.
                </div>
            </div>
            <div className={"reporting-chart"}>
                <h2>Détail des tâches terminées </h2>
                <Tasks data={totalTasks} hidePagination={false}
                       headersData={["title", "name_1", "article_code", "time_spent_reporting", "task_price_prorata", "finish_date_reporting", "username_reporting"]}
                       headers={["Tâche", "Client", "Code article", "Temps passé", "Prix", "Date", "Personne"]}
                       tasks={totalTasks}/>

            </div>
        </div>
    );
};

export default Reporting;
