import React, {useEffect, useState, Fragment} from 'react';
import {Link, useParams} from 'react-router-dom';
import axios from 'axios';
import {API_BASE_URL} from '../config';
import '../assets/css/Client.css';
import Table from "../components/Table";
import SearchBar from "../components/SearchBar";
import ClientInfoForm from "../components/Client/ClientInfoForm";

const Client = () => {
    const {id} = useParams(); // Get the 'id' parameter from the URL
    const [positions, setPositions] = useState([]);
    const [totalPositions, setTotalPositions] = useState([]);
    const [customerName1, setCustomerName1] = useState(null);
    const [customerName2, setCustomerName2] = useState(null);
    const [email, setEmail] = useState(null);
    const [consultantEmail, setConsultantEmail] = useState(null);
    const [feedback, setFeeback] = useState(null);
    const [messageState, setMessageState] = useState('Envoyer la demande d\'avis');
    const [avis, setAvis] = useState([]); // Initialize as an empty array
    const [isChecked, setIsChecked] = useState(false);
    const [sortBy, setSortBy] = useState({column: null, ascending: true});
    const [formData, setFormData] = useState({
        subject: '', url: '', clientEmail: '', clientName: '', contentFile: '', clientID: ''
    });


    const [selectedTemplate, setSelectedTemplate] = useState('');
    const [siteUrl, setSiteUrl] = useState('');

    const handleTemplateChange = (e) => {
        const value = e.target.value;
        setSelectedTemplate(value);
        setFormData({...formData, subject: value});
        console.log(value)
        if (value !== "Votre nouveau site est en ligne") {
            setSiteUrl('');
        }
    };


    const handleSiteUrlChange = (e) => {
        const value = e.target.value;
        setSiteUrl(value);
        setFormData({...formData, url: value});
    };

    const columnMapping = {
        "Identifiant": "id", "Nom du projet": "title", 'Date de création du projet': "creation_date"
        // Add more mappings as needed
    };
    const handleSort = (columnName) => {
        const actualColumn = columnMapping[columnName] || columnName;
        const ascending = actualColumn === sortBy.column ? !sortBy.ascending : true;
        setSortBy({column: actualColumn, ascending});
    };

    const publishedArray = Array.isArray(positions) ? positions : [];
    const sortedArray = [...publishedArray].sort((a, b) => {
        if (sortBy.column === 'id') {
            return sortBy.ascending ? a.id - b.id : b.id - a.id;
        } else if (sortBy.column === 'title') {
            return sortBy.ascending ? a.title.localeCompare(b.title) : b.title.localeCompare(a.title);
        } else if (sortBy.column === 'creation_date') {
            const branchNameA = a.branch_names;
            const branchNameB = b.branch_names;

            if (branchNameA === null || branchNameA === undefined) return sortBy.ascending ? 1 : -1;
            if (branchNameB === null || branchNameB === undefined) return sortBy.ascending ? -1 : 1;

            return sortBy.ascending ? branchNameA.localeCompare(branchNameB) : branchNameB.localeCompare(branchNameA);
        }
        return 0; // Default case
    });
    const handleToggle = () => {
        const newValue = !isChecked ? 1 : 0;
        setIsChecked(!isChecked);
        axios.post(`${API_BASE_URL}/update_customer_avis`, {id, avis: newValue});
    };

    useEffect(() => {
        console.log(id);

        // Fetch customer avis
        axios.post(`${API_BASE_URL}/get_customer_avis`, {id: id})
            .then(res => {
                setAvis(res.data.datas);
                // Check if res.data.datas exists and has email property
                if (res.data.datas && res.data.datas.email !== undefined) {
                    setEmail(res.data.datas.email);
                }

            });

        // Fetch customer info
        axios.post(`${API_BASE_URL}/get_customer_info`, {id})
            .then(res => {
                console.log(res.data[0])
                if (res.data[0]) {
                    setConsultantEmail(res.data[0].email)
                    setEmail(res.data[0].mail)
                    setSiteUrl(res.data[0].url)
                    console.log(res.data)
                    setFormData(res.data[0])
                    setCustomerName1(res.data[0].name_1);
                    setCustomerName2(res.data[0].name_2);
                    setIsChecked(res.data[0].avis === 1);
                    document.title = `X | Client - ${res.data[0].name_1}`;
                }
            });

        // Fetch customer positions
        axios.post(`${API_BASE_URL}/get_customer_positions`, {id})
            .then(res => {
                setPositions(res.data);
                setTotalPositions(res.data);
            });
    }, [messageState, id]);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const seconds = date.getSeconds().toString().padStart(2, '0');

        return `${day}/${month}/${year} à ${hours}:${minutes}:${seconds}`;
    };

    const handleChangeEmail = (val) => {
        setEmail(val)
    }

    const validateForm = () => {
        if (formData.subject === '') {
            alert("Vous devez sélectionner un modèle d'email");
            return false;
        }

        if (formData.subject === 'Votre nouveau site internet est en ligne' && formData.url === "") {
            alert("Vous devez remplir le champ URL pour cette demande");
            return false;
        }

        return true;
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!validateForm()) {
            return;
        }

        // eslint-disable-next-line no-restricted-globals
        if (confirm(`Voulez-vous vraiment envoyer la demande d'avis à ${email} (en copie à ${consultantEmail})`)) {

            const data = {
                consultant: null,
                clientID: id,
                email: email,
                consultantEmail: consultantEmail,
                subject: formData.subject,
                contentFile: formData.contentFile,
                clientName: formData.clientName,
                url: siteUrl
            };

            axios.post(`${API_BASE_URL}/send_mail`, data)
                .then(response => {

                    console.log(response)
                    if (response.data === 'ok') {
                        setMessageState('<i class="fa-solid fa-check"></i>')
                    }
                    setFeeback(response.data)
                })
                .catch(error => {
                    alert('Erreur lors de l\'envoi : ', error);
                });
        } else {
            console.log("User cancelled the action. Stopping execution.");
        }
    };


    return (<div className={"container m-20"}
                 style={totalPositions.length === 0 ? {backgroundColor: "#ACDCE6", height: "calc(100vh - 80px)"} : {}}>
        <div className={'display-grid'}>

            {customerName1 && <h2>{customerName1}</h2>}
            {customerName2 && <h3>{customerName2}</h3>}

            <Link to={'/clients'} className={"mb-20 back-button"}>
          <span className={"cursor-pointer"}>
            <i className="fa-solid fa-left"/>
          </span>
            </Link>

            {totalPositions.length === 0 ? (<div className="client-no-client">
                <img alt="Toucan tristounet" src="../assets/sad_toucan.jpg"
                     style={{marginBottom: "40px", maxWidth: "100%"}}/>
                <span>On dirait que ce client n'a pas encore de projet chez Solution Digitale...</span>
            </div>) : (<Fragment>
                <SearchBar
                    totalPositions={totalPositions}
                    setPublished={setPositions}
                    totalPositionsLength={publishedArray}
                    positionLabel={customerName1}
                    searchLabel={'projets trouvés pour ce client'}
                />
                <Table
                    typeOfPosition={"finished"}
                    data={sortedArray}
                    sortBy={sortBy}
                    hidePagination={false}
                    maxEntries={50}
                    headersData={['id_customer', 'title_customer', 'order_start_date']}
                    headers={["Identifiant", "Nom du projet", 'Date de création du projet']}
                    handleSort={handleSort}
                />

                <div className={"text-left mb-50 mt-50"}>
                    <h3>Gestion des avis</h3>
                    <div>
                        <span>Statut de l'avis client sur Google</span>
                    </div>
                    <label className="switch mt-20">
                        <input type="checkbox" checked={isChecked} onChange={handleToggle}/>
                        <span className="slider"></span>
                    </label>
                    <div className={"mt-10"}>

                        <span className={"mt-10"} style={{fontStyle: "italic", fontSize: ".8rem"}}>Si la case est cochée, le client a déjà laissé son avis sur Google.
                        </span>
                    </div>
                </div>

                <div className={"text-left mb-50"}>
                    <h3>Gestionnaire d'envoi des avis (fiche Google)</h3>
                    <form onSubmit={handleSubmit}>
                        <label>Pour mettre plusieurs emails, les séparer par une virgule</label>
                        <div className={"display-flex gap-10"}>
                            <div className={"display-grid text-left"}>
                                <input
                                    autoFocus={true}
                                    className={"text-center input-base"}
                                    name="clientEmail"
                                    value={email}
                                    onChange={e => handleChangeEmail(e.target.value)}
                                    placeholder={"Email client (aucun trouvé)"}
                                    style={{minWidth: 300}}
                                />
                            </div>
                            <div className={"display-grid text-left"}>
                                <select
                                    id="subject"
                                    name="subject"
                                    className="form-control input-base"
                                    value={formData.subject}
                                    onChange={handleTemplateChange}
                                    style={{minWidth: 300}}
                                >
                                    <option value="">Choisir un template d'email</option>
                                    <option value="Demande d'avis">Demande d'avis</option>
                                    <option value="Rappel demande d'avis">Rappel demande d'avis</option>
                                    <option value="Votre nouveau site est en ligne">Votre nouveau site internet est en
                                        ligne
                                    </option>
                                    <option value="Vos supports de communication sont prêts">Vos supports de
                                        communication sont prêts
                                    </option>
                                    <option value="Votre présence sur les réseaux sociaux">Votre présence sur les
                                        réseaux sociaux
                                    </option>
                                    <option value="Lancement de votre campagne Google Ads">Lancement de votre campagne
                                        Google Ads
                                    </option>
                                </select>
                            </div>
                            {(selectedTemplate === "Votre nouveau site est en ligne") && (
                                <div className={"display-grid text-left"}>
                                <input
                                        className={"text-center input-base"}
                                        name="siteUrl"
                                        value={siteUrl}
                                        required={true}
                                        onChange={handleSiteUrlChange}
                                        placeholder={"URL du site"}
                                        style={{minWidth: 300}}
                                    />
                                </div>)}
                            <button type={"submit"} className={"btn-submit-account"}
                                    dangerouslySetInnerHTML={{__html: messageState}}></button>
                        </div>
                    </form>
                </div>

                <div className={"text-left mb-50"}>
                    <h3>Demandes d'avis envoyées</h3>
                    {avis ? avis.map((el, index) => (<div key={index}>
                        <span><strong>{el.type}</strong> envoyé le {formatDate(el.created_date)} à {el.email}</span>
                    </div>)) : <span>Aucune demande d'avis n'a pour le moment été envoyée.</span>}
                </div>
            </Fragment>)}
        </div>
    </div>);
};

export default Client;