import React, { useEffect, useState, Fragment } from 'react';
import { Link, NavLink } from "react-router-dom";
import axios from "axios";
import { API_BASE_URL } from "../config";
import MenuOption from "./MenuOption";

const Navbar = ({ setIsNavbarToggled }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [loadingAuth, setLoadingAuth] = useState(true); // New loading state for auth check

    useEffect(() => {
        const checkAuth = async () => {
            const token = localStorage.getItem('token');
            const userId = localStorage.getItem('user_id');

            if (token && userId) {
                try {
                    const response = await axios.post(`${API_BASE_URL}/check_auth`, { token, userId });
                    if (response.data && response.data.status === 'ok') {
                        setIsAuthenticated(true);
                    } else {
                        setIsAuthenticated(false);
                        localStorage.removeItem('token');
                        localStorage.removeItem('user_id');
                    }
                } catch (error) {
                    console.error('Error checking authentication:', error);
                    setIsAuthenticated(false);
                }
            } else {
                setIsAuthenticated(false);
            }
            setLoadingAuth(false); // Set loading to false after auth check
        };

        checkAuth();
    }, []);

    // While the auth check is loading, return null or a placeholder
    if (loadingAuth) {
        return null; // or <div>Loading...</div> for a loading indicator
    }

    return (
        <Fragment>
            <style>
                {`
                    a.active {
                        font-weight: bold;
                        color: #007bff; /* Change to your desired color */
                        border-bottom: 2px solid #007bff; /* Optional underline for active links */
                    }

                    a.active > .nav-link {
                        background-color: var(--primary);
                        border-radius: 10px;
                        transition: background-color .3s ease;
                        cursor: pointer;
                        color: white;
                    }

                    a.active > h4 {
                        color: #007bff; /* Change to your desired color for active state */
                    }

                    .navbar-container {
                        transition: all 0.3s ease-in-out;
                        /* Add your default styles for the navbar here */
                    }
                `}
            </style>

            <div className={'navbar-container'} style={{ display: "grid" }}>
                <div className={"navbar-main-container"}>
                    <Fragment>
                        <div className={"navbar-title"}>
                            <Link to={'/'} activeClassName="active">
                                <div className={"navbar-title"}>
                                    <img
                                        src={'https://solution-digitale.ch/wp-content/uploads/2021/03/logo-solution-digitale-300-1.png'}
                                        style={{ maxWidth: "200px" }}
                                        alt={'Logo Solution-Digitale'}
                                    />
                                </div>
                            </Link>
                        </div>
                    </Fragment>
                    {isAuthenticated && (
                        <Fragment>
                            <div className={"navbar-title"}>
                                <h2>X</h2>
                                <div className={"navbar-sub-title"}>
                                    <ul>
                                        <li>
                                            <h3>Outils</h3>
                                            <ul>
                                                {/*} <NavLink to={'/calendrier'} activeClassName="active">
                                                    <h4 className={"nav-link"}>
                                                        <MenuOption type={'wip'}>Calendrier</MenuOption>
                                                    </h4>
                                                </NavLink>

                                                <NavLink to={'/trello'} activeClassName="active">
                                                    <h4 className={"nav-link"}>
                                                        <MenuOption type={'wip'}>Trello</MenuOption>
                                                    </h4>
                                                </NavLink>*/}
                                                <NavLink to={'/seo'} activeClassName="active">
                                                    <h4 className={"nav-link"}>Meta Generator
                                                    </h4>
                                                </NavLink>
                                                <NavLink to={"/seo-checker"} activeClassName="active">
                                                    <h4 className={"nav-link"}>SEO Checker
                                                    </h4>
                                                </NavLink>
                                                <NavLink to={'/qrcodes'} activeClassName="active">
                                                    <h4 className={"nav-link"}>QRCodes</h4>
                                                </NavLink>
                                                <NavLink to={'/emails'} activeClassName="active">
                                                    <h4 className={"nav-link"}>Email
                                                    </h4>
                                                </NavLink>
                                                <NavLink to="/dns-lookup" activeClassName="active">
                                                    <h4 className="nav-link">DNS Lookup
                                                    </h4>
                                                </NavLink>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className={"navbar-title"}>
                                <h2>Sites internet</h2>
                                <div className={"navbar-sub-title"}>
                                    <ul>
                                        <h3>Sites internet</h3>
                                        <li>
                                            <ul>
                                                <NavLink to={'/sites-publies'} activeClassName="active">
                                                    <h4 className={"nav-link"}>Sites publiés</h4>
                                                </NavLink>
                                                <NavLink to={'/sites-visu'} activeClassName="active">
                                                    <h4 className={"nav-link"}>Sites visu</h4>
                                                </NavLink>
                                            </ul>
                                        </li>
                                        <h3>Tâches</h3>
                                        <li>
                                            <ul>
                                                <NavLink to={'/liste-taches'} activeClassName="active">
                                                    <h4 className={"nav-link"}>Liste de mes tâches</h4>
                                                </NavLink>
                                                {localStorage.user_role === "Administrateur" && (
                                                    <NavLink to={'/liste-toutes-taches'} activeClassName="active">
                                                        <h4 className={"nav-link"}>Liste des tâches</h4>
                                                    </NavLink>
                                                )}
                                            </ul>
                                        </li>
                                        <h3>Bexio</h3>
                                        <li>
                                            <ul>
                                                <NavLink to={'/clients'} activeClassName="active"><h4
                                                    className={"nav-link"}>
                                                    Clients</h4>
                                                </NavLink>
                                                <NavLink to={'/positions-en-cours'} activeClassName="active">
                                                    <h4 className={"nav-link"}>Projets en cours</h4>
                                                </NavLink>
                                                <NavLink to={'/positions-en-suivi'} activeClassName="active">
                                                    <h4 className={"nav-link"}>Projets en suivi</h4>
                                                </NavLink>
                                                <NavLink to={'/positions-terminees'} activeClassName="active">
                                                    <h4 className={"nav-link"}>Projets terminés</h4>
                                                </NavLink>
                                                {localStorage.user_role === "Administrateur" && (
                                                    <NavLink to={'/positions-supprimees'} activeClassName="active">
                                                        <h4 className={"nav-link"}>Projets supprimés récemment</h4>
                                                    </NavLink>
                                                )}
                                                {localStorage.user_role === "Administrateur" && (
                                                    <NavLink to={'/maj-bexio'} activeClassName="active">
                                                        <h4 className="nav-link"><MenuOption type={'new'}>Mise à jour
                                                            bexio</MenuOption></h4>
                                                    </NavLink>
                                                )}
                                                <NavLink to={'/produits'} activeClassName="active">
                                                    <h4 className={"nav-link"}>Tous les produits</h4>
                                                </NavLink>
                                                <NavLink to={'/creation-taches'} activeClassName="active">
                                                    <h4 className={"nav-link"}>
                                                        Importer les tâches par défaut
                                                    </h4>
                                                </NavLink>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            {localStorage.user_role === "Administrateur" && (
                                <div className={"navbar-title"}>
                                    <h2>Options</h2>
                                    <div className="navbar-sub-title">
                                        <ul>
                                            <h3>Gestion</h3>
                                            <li>
                                                <ul>
                                                    <NavLink to="register" activeClassName="active">
                                                        <h4 className="nav-link">Ajout d'un utilisateur</h4>
                                                    </NavLink>
                                                </ul>
                                            </li>
                                        </ul>
                                        <ul>
                                            <h3>Reporting</h3>
                                            <li>
                                                <ul>
                                                    <NavLink to="reporting" activeClassName="active">
                                                        <h4 className="nav-link"><MenuOption
                                                            type={'wip'}>Vue de la production</MenuOption></h4>
                                                    </NavLink>

                                                </ul>
                                                <ul>
                                                    <NavLink to="productivite" activeClassName="active">
                                                        <h4 className="nav-link"><MenuOption
                                                            type={'wip'}>Productivité</MenuOption></h4>
                                                    </NavLink>

                                                </ul>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            )}
                        </Fragment>
                    )}
                </div>
                {isAuthenticated && (
                    <div className="footer" style={{alignSelf: "end"}}>
                        <div className="footer-container">
                            <div style={{padding: "15px"}}>
                                <NavLink to="logout" activeClassName="active">
                                    <i className="fa-solid fa-arrow-right-from-bracket icon-footer"/>
                                </NavLink>
                            </div>
                            <div style={{padding: "15px", position: "relative"}}>
                                <NavLink to="account" activeClassName="active">
                                    <i className="fa-regular fa-user icon-footer"/>
                                </NavLink>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </Fragment>
    );
};

export default Navbar;
