import React, {useEffect, useState} from 'react';
import axios from "axios";
import {API_BASE_URL} from "../config";
import ToggleContent from "../components/ToggleContent";
import Tasks from "../components/Tasks";


const ListeTaches = () => {

    const [userTasks, setUserTasks] = useState([])
    const [finishedTasks, setFinishedTasks] = useState([])
    const [inProgressTasks, setInProgressTasks] = useState([]);

    useEffect(() => {
        document.title = 'X | Mes tâches'
    }, []);

    useEffect(() => {
        axios.post(`${API_BASE_URL}/get_all_tasks_by_user`, {user_id: localStorage.user_id})
            .then(res => {
                const tasks = res.data.datas;
                setUserTasks(tasks);

                // Separate tasks based on progress
                const finished = tasks.filter(task => parseInt(task.progress, 10) === 100);
                const inProgress = tasks.filter(task => parseInt(task.progress, 10) < 100);

                setFinishedTasks(finished);
                setInProgressTasks(inProgress);

                console.log('All tasks:', tasks);
                console.log('Finished tasks:', finished);
                console.log('In-progress tasks:', inProgress);
            })
            .catch(error => {
                console.error('Error fetching tasks:', error);
            });
    }, []);

    return (<div>
        <h2>Mes tâches</h2>

        <div className={'position-component'}>
            <Tasks
                displayCheckbox={true}
                displayAdminBar={true}
                title={"Tâches en cours"}
                tasks={inProgressTasks}
                headers={["Progression", "Nom de la tâche", "Date de réalisation", "Durée", "Terminé le", "Client"]}
                headersData={["progress", "title", "start_date", "time_spent", "finish_date_custom", "name_1"]}
            />
            <Tasks
                displayAdminBar={false}
                title={"Tâches terminées"}
                tasks={finishedTasks}
                headers={["Nom de la tâche", "Client", "Type de projet", "Terminé le", "Durée"]}
                headersData={["title_finished", "name_1", "intern_name", "finished_at", "time_spent"]}
            />
        </div>

    </div>);
};

export default ListeTaches;