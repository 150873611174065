import React, {Fragment, useEffect, useState} from 'react';
import Slider from "rc-slider";
import axios from "axios";
import {API_BASE_URL} from "../config";
import {NavLink} from "react-router-dom";
import 'rc-slider/assets/index.css';

const Tasks = ({title, displayAdminBar, displayCheckbox, tasks: initialTasks, headers, headersData}) => {
    // Local state to manage tasks internally
    const [tasks, setTasks] = useState(initialTasks);
    const [durationLighting, setDurationLighting] = useState(false);
    const [timeSpent, setTimeSpent] = useState()
    const [selectedTasks, setSelectedTasks] = useState([]);
    const [users, setUsers] = useState([]);

    const handleAction = (action, id) => {

        if (action === 'delete_task') {
            const res = window.confirm("Voulez-vous vraiment supprimer cette tâche ?");
            if (res) {
                axios.post(`${API_BASE_URL}/delete_task`, {id})
                    .then(response => {
                        if (response.data.status === 'ok') {
                            setTasks(prevEntries => prevEntries.filter(entry => entry.id !== id));
                        } else {
                            console.error('error');
                        }
                    })
                    .catch(error => {
                        console.error('Error deleting task:', error);
                    });
            }
        }
    };

    useEffect(() => {
        axios.post(`${API_BASE_URL}/get_users`)
            .then(res => {
                const {data} = res;
                if (data && data.length > 0) {
                    const userNamesArray = data.map(user => user); // Extract usernames from each user
                    setUsers(userNamesArray); // Set the usernames in state

                } else {
                    console.error('No user data received');
                }
            })
            .catch(error => {
                console.error('Error fetching user data:', error);
            });
    }, [tasks]); // Include tasks in the dependency array
    console.log(tasks)
    // Handle progress change for a task

    const handleFinishDate = (tasksId, newDate) => {
        const updatedTaskIds = Array.isArray(tasksId) ? tasksId : [tasksId]; // Ensure taskIds is an array
        console.log(newDate)
        updatedTaskIds.forEach(taskId => {

            axios.post(`${API_BASE_URL}/update_task_finish_date`, {time: newDate, id_task: taskId})
                .then(response => {
                    // reload la page pour afficher la nouvelle date...
                    //window.location.reload()

                    setTasks(prevTasks => prevTasks.map(task => task.id === taskId ? {
                        ...task, start_date: newDate
                    } : task))
                });
        })
    };
    const handleFinishDateCustom = (tasksId, newDate) => {
        const updatedTaskIds = Array.isArray(tasksId) ? tasksId : [tasksId]; // Ensure taskIds is an array
        console.log(newDate)
        updatedTaskIds.forEach(taskId => {

            axios.post(`${API_BASE_URL}/update_task_finish_date_custom`, {time: newDate, id_task: taskId})
                .then(response => {
                    // reload la page pour afficher la nouvelle date...
                    //window.location.reload()

                    setTasks(prevTasks => prevTasks.map(task => task.id === taskId ? {
                        ...task, finished_at: newDate
                    } : task))
                });
        })
    };
    const handleUserChange = (tasksId, userId) => {
        const updatedTaskIds = Array.isArray(tasksId) ? tasksId : [tasksId]; // Ensure tasksId is an array

        updatedTaskIds.forEach(taskId => {
            // Optimistically update the UI
            setTasks(prevTasks => prevTasks.map(task => task.id === taskId ? {
                ...task, user_id: userId
            } : task));

            axios.post(`${API_BASE_URL}/update_user_task`, {
                user_id: userId, task_id: taskId, admin_id: localStorage.user_id
            })
                .then((res) => {
                    if (res.status !== 200) { // Revert the optimistic update if the API call fails
                        setTasks(prevTasks => prevTasks.map(task => task.id === taskId ? {
                            ...task, user_id: null // Assuming `null` reverts the assignment
                        } : task));
                    }
                })
                .catch(error => {
                    console.error('Error updating user task:', error);
                    // Revert the optimistic update if the API call fails
                    setTasks(prevTasks => prevTasks.map(task => task.id === taskId ? {
                        ...task, user_id: null // Assuming `null` reverts the assignment
                    } : task));
                });
        });
    };

    const handleSelectAll = (event) => {
        const userName = localStorage.getItem('user_name'); // Retrieve the username from localStorage
        const userRole = localStorage.getItem('user_role'); // Retrieve the user role from localStorage

        if (event.target.checked) {
            const allTaskIds = tasks
                .filter(task => userRole === 'Administrateur' || task.username === userName) // Check role first
                .map(task => task.id); // Map only the task IDs
            setSelectedTasks(allTaskIds);
        } else {
            setSelectedTasks([]);
        }
    };


    const handleCheckboxChange = (taskId) => {
        if (selectedTasks.includes(taskId)) {
            setSelectedTasks(selectedTasks.filter(id => id !== taskId));
        } else {
            setSelectedTasks([...selectedTasks, taskId]);
        }
    };

    const formatDateForInput = (date, formatForDisplay = false, isDateTime = false) => {
        if (!date) {
            return formatForDisplay ? 'jj/mm/aaaa hh:mm:ss' : '';  // Placeholder based on context
        }

        const d = new Date(date);
        if (isNaN(d.getTime())) {
            return formatForDisplay ? 'jj/mm/aaaa hh:mm:ss' : '';  // Placeholder for invalid date
        }

        const day = d.getDate().toString().padStart(2, '0');
        const month = (d.getMonth() + 1).toString().padStart(2, '0');
        const year = d.getFullYear();
        const hours = d.getHours().toString().padStart(2, '0');
        const minutes = d.getMinutes().toString().padStart(2, '0');
        const seconds = d.getSeconds().toString().padStart(2, '0');

        if (formatForDisplay) {
            // For display format (jj/mm/yyyy hh:mm:ss)
            return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
        } else if (isDateTime) {
            // For datetime-local input (yyyy-MM-ddTHH:mm:ss)
            return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
        } else {
            return `${year}-${month}-${day}`;  // For date input (yyyy-MM-dd)
        }
    };
    const handleTimeSpent = (user, time, id) => {
        setTimeSpent(time)
        axios.post(`${API_BASE_URL}/update_time_spent`, {user, time, id_task: id})
            .then(response => {

                setTasks(prevTasks => prevTasks.map(task => task.id === id ? {
                    ...task, time_spent: time
                } : task))
                console.log(response)
            })
            .catch(error => console.error('Error updating time spent:', error))
    }

    useEffect(() => {
        if (durationLighting) {
            alert(durationLighting);
            setDurationLighting(false); // Reset the state after showing the alert
        }
    }, [durationLighting]);

    // Use useEffect to update the tasks state when the initialTasks prop changes
    useEffect(() => {
        setTasks(initialTasks);
    }, [initialTasks]);
    const handleProgressChange = (taskId, value) => {
        console.log(value);

        // Optimistically update the UI
        setTasks(prevTasks => prevTasks.map(task => task.id === taskId ? {...task, progress: value} : task));

        // Assuming you have an API endpoint to update task progress
        axios.post(`${API_BASE_URL}/update_task_progress`, {
            taskId, progress: value,
        }).then(response => {
            const {data} = response;
            console.log(data)
            if (data.status !== 'ok') {
                console.error('Error updating task progress:', data.message);
                setDurationLighting(data.message)
            }
        }).catch(error => {
            console.error('Error updating task progress:', error);
            setDurationLighting(true);
        });
    };

    return (<div>
        <div style={{marginBottom: "-10px"}}>
            <div className="toggle-container">
                {title && (<div className="toggle-button">{title} ({tasks.length})</div>)}

                {(displayAdminBar) && (
                    <div className="selected-tasks-options">
                        <button className={'button-action shadow mt-10'} style={{backgroundColor: '#F6F8FA'}}
                                onClick={() => handleProgressChange(selectedTasks, 100)}>
                            Mettre à 100%
                        </button>
                        {(localStorage.user_role !== 'test') && (<Fragment>
                                <span className={'button-action shadow mt-10 mr-10'}
                                      style={{backgroundColor: '#F6F8FA'}}>
                                    Assigner à...
                                </span>
                            <select className={'select-assign'} onChange={(e) => {
                                handleUserChange(selectedTasks, e.target.value);
                            }}
                            >
                                {users.map((user) => (<option
                                    value={user.id}
                                    name={user.username}
                                >
                                    {user.username}
                                </option>))}
                            </select>
                            <button className={'button-action shadow mt-10'}
                                    style={{backgroundColor: '#F6F8FA'}}>Date de réalisation
                            </button>
                            <input type={"date"} onChange={(e) => handleFinishDate(selectedTasks, e.target.value)}/>

                        </Fragment>)}

                    </div>)}

                <div className="show">
                    <table className="task-table">
                        <thead>
                        <tr>
                            {displayCheckbox && <th><input type="checkbox" onChange={handleSelectAll}/></th>}
                            {headers.map((header, index) => (<th key={index} id={header}>{header}</th>

                            ))}

                        </tr>
                        </thead>
                        <tbody>
                        {tasks.map((item, rowIndex) => (<tr key={rowIndex}>
                            {displayCheckbox && (<td
                                style={{textAlign: "center", cursor: "pointer"}}
                                onClick={(e) => {
                                    if (e.target.tagName !== 'INPUT') {
                                        handleCheckboxChange(item.id);
                                    }
                                }}
                            >
                                {(localStorage.user_name === item.username || localStorage.user_role === 'Administrateur') &&

                                    <input
                                        type="checkbox"
                                        checked={selectedTasks.includes(item.id)}
                                        onClick={(e) => e.stopPropagation()}
                                        onChange={() => handleCheckboxChange(item.id)}
                                    />

                                }
                            </td>)}

                            {headersData.map((header, colIndex) => {

                                if (header === 'name_1') {
                                    console.log(item)
                                    return (<td key={colIndex}>
                                        <a
                                            href={`/client/${item.contact_id}`}
                                            className="text-decoration-none text-left"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            {item.name_1}
                                            <p>{item.name_2}</p>
                                        </a>
                                    </td>);
                                }
                                if (header === 'customer') {
                                    return (<td key={colIndex}>
                                        <a
                                            href={`/client/${item.contact_id}`}
                                            className="text-decoration-none text-left"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <p>{item.name_1}</p>
                                            <p>{item.name_2}</p>
                                        </a>
                                    </td>);
                                }
                                if (header === 'time_spent_reporting') {
                                    return (<td key={colIndex}>

                                        {item.time_spent}
                                    </td>);
                                }

                                if (header === 'task_price_prorata') {
                                    return (
                                        <td key={colIndex}>
                                            {new Intl.NumberFormat('de-CH', {
                                                style: 'currency',
                                                currency: 'CHF'
                                            }).format(item.task_price_prorata)}
                                        </td>
                                    );
                                }


                                if (header === 'username_reporting') {
                                    return (<td key={colIndex}>

                                        {item.username}
                                    </td>);
                                }
                                if (header === 'title_finished' || header === 'title') {
                                    return (<td key={colIndex}>
                                        <NavLink
                                            to={`/positions/${item.position_id}`}
                                            className="text-decoration-none text-left"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <strong>
                                                {item.title}

                                            </strong>

                                        </NavLink>
                                    </td>);
                                }
                                if (header === 'title_open_position') {
                                    return (<td key={colIndex}>

                                        <strong>
                                            {item.title}

                                        </strong>

                                    </td>);
                                }
                                if (header === 'finished_at') {
                                    return (<td key={colIndex} className={'text-center'}>
                                        <span>{formatDateForInput(item.finished_at, true)}</span>

                                    </td>);
                                }
                                if (header === 'username') {
                                    console.log(item.username)
                                    return (<td>
                                            {localStorage.user_role ? (
                                                <select
                                                    className={"input text-center"}
                                                    style={{
                                                        minWidth: 150,
                                                        padding: "10px 5px",
                                                        fontFamily: "Montserrat"
                                                    }}
                                                    onChange={(e) => {
                                                        handleUserChange(item.id, e.target.value);
                                                    }}
                                                    value={item.user_id || ""} // Ensure correct value is set, empty string for "Non assigné"
                                                >
                                                    <option value="">Non assigné</option>
                                                    {/* Option for "Non assigné" */}
                                                    {users.map((user) => (
                                                        <option key={user.id} value={user.id}>
                                                            {user.username}
                                                        </option>
                                                    ))}
                                                </select>
                                            ) : (
                                                <div>
                                                    {item.username || "Non assigné"} {/* Display "Non assigné" if item.username is null */}
                                                </div>
                                            )}
                                        </td>
                                    );
                                }
                                if (header === 'progress') {
                                    return (<td key={colIndex} className="progress-td">
                                        {((parseInt(localStorage.user_id)) === parseInt(item.user_id) || localStorage.user_role === 'Administrateur') && (
                                            <Slider
                                                min={0}
                                                max={100}
                                                defaultValue={item.progress}
                                                onAfterChange={value => handleProgressChange(item.id, value)} // Call after change is done
                                            />)}
                                        <span style={{
                                            display: "flex", justifyContent: "center", marginTop: "10px"
                                        }}>
                                                            {item.progress}%
                                                        </span>
                                    </td>);
                                }
                                if (header === 'intern_name') {
                                    return (<td key={colIndex} className="progress-td">
                                        <span className={""}>{item.intern_name}</span>
                                    </td>);
                                }
                                if (header === 'finish_date_reporting') {
                                    const finishDate = new Date(item.finish_date);  // Parse the finish_date string into a Date object
                                    const formattedDate = `${finishDate.getDate().toString().padStart(2, '0')}/${(finishDate.getMonth() + 1).toString().padStart(2, '0')}/${finishDate.getFullYear()}`;

                                    return (
                                        <td key={colIndex} className="progress-td">
            <span>
                {formattedDate}
            </span>
                                        </td>
                                    );
                                }
                                if (header === 'finish_date' || header === 'start_date') {
                                    return (<td>
                                        <div className={'input-time-container'}>
                                            {localStorage.user_role === "Administrateur" ? (<input
                                                className={'input-time input'}
                                                type={"datetime-local"}
                                                onChange={(e) => handleFinishDate(item.id, e.target.value)}
                                                value={formatDateForInput(item.start_date, false, true)}  // datetime-local format (yyyy-MM-ddTHH:mm:ss)
                                            />) : (<span className='input-time display-flex justify-content-center'>
                                                {formatDateForInput(item.start_date, true)}
                                            </span>)}
                                        </div>
                                    </td>);
                                }
                                if (header === 'finish_date_custom') {

                                    console.log(item)
                                    return (<td>
                                        <div className={'input-time-container'}>
                                            <input
                                                className={'input-time input'}
                                                type={"datetime-local"}
                                                onChange={(e) => handleFinishDateCustom(item.id, e.target.value)}
                                                value={formatDateForInput(item.finished_at, false, true)}  // datetime-local format (yyyy-MM-ddTHH:mm:ss)
                                            />
                                        </div>
                                    </td>);
                                }
                                if (header === 'time_spent') {
                                    return (<td>
                                        <div className={'input-time-container'}>
                                            {((parseInt(localStorage.user_id)) === parseInt(item.user_id) || localStorage.user_role === 'Administrateur') && (
                                                <input
                                                    className={'input-time input'}
                                                    style={{padding: "10px 5px", height: "40px"}}
                                                    type={'time'}
                                                    onChange={(e) => handleTimeSpent(item.user_id, e.target.value, item.id)}
                                                    onBlur={(e) => handleTimeSpent(item.user_id, e.target.value, item.id)} // Reload when input loses focus
                                                    value={item.time_spent}
                                                />)}
                                        </div>
                                    </td>);
                                }
                                if (header === 'delete_task' && localStorage.user_role === 'Administrateur') {
                                    return (<td>
                                        <span className="info-branch">
                                             <i style={{
                                                 color: "orangered", fontSize: "21px"
                                             }}
                                                className="cursor-pointer position-icon fa-solid position-icon fa-trash"
                                                onClick={() => handleAction('delete_task', item.id)}></i>
                                        </span>
                                    </td>);
                                }
                                return (<td key={colIndex}>
                                    <p className="t-0 d-ib">{item[header]}</p>
                                </td>);
                            })}
                        </tr>))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>);
};

export default Tasks;
