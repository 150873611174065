import React, {useEffect} from 'react';
import WIP from "../components/WIP";

const CreationTaches = () => {
    useEffect(() => {

        document.title = 'X | Création des tâches par défaut'
    }, []);
    return (
        <div>
            <h2>Création des tâches par défaut</h2>
            <WIP />
        </div>
    );
};

export default CreationTaches;