// custom SD Format date
export const formatDate = (date) => {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    return `${date.getFullYear()}-${month}-${day}`;
};

export const getDateRange = (filter, customStartDate, customEndDate) => {
    const endDate = customEndDate || new Date();
    let startDate = new Date(endDate);
    let days = 0;

    switch (filter) {
        case 'last30Days': days = 30; break;
        case 'last60Days': days = 60; break;
        case 'lastWeek': days = 6; break;
        case 'last3Months': days = 90; break;
        case 'last6Months': days = 180; break;
        case 'lastYear': days = 365; break;
        case 'thisWeek':
            const dayOfWeek = endDate.getDay();
            if (dayOfWeek === 1) {
                // Today is Monday, show only Monday
                startDate = new Date(endDate);
            } else {
                // Adjust so that Monday is considered the start of the week
                const mondayOffset = (dayOfWeek === 0 ? -6 : 1) - dayOfWeek;
                startDate = new Date(endDate);
                startDate.setDate(endDate.getDate() + mondayOffset);
            }
            break;
        case 'thisMonth':
            startDate = new Date(endDate.getFullYear(), endDate.getMonth(), 1);
            break;
        case 'thisYear':
            startDate = new Date(endDate.getFullYear(), 0, 1);
            break;
        case 'custom':
            if (customStartDate) {
                startDate = new Date(customStartDate);
                days = (customEndDate - customStartDate) / (1000 * 60 * 60 * 24) + 1;
            }
            break;
        default: return [];
    }

    if (!['custom', 'thisWeek', 'thisMonth', 'thisYear'].includes(filter)) {
        startDate.setDate(endDate.getDate() - days);
    }

    const dateRange = [];
    while (startDate <= endDate) {
        dateRange.push(formatDate(new Date(startDate)));
        startDate.setDate(startDate.getDate() + 1);
    }

    return dateRange;
};
